.signInPage {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.infoBlock {
    max-width: 615px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;
}

h1 {
    color: #19191C;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
}
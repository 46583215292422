.title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
  text-align: center;
  color: var(--grey-100);
  margin-top: 160px;
  margin-bottom: 32px;
}

.subtitle {
  margin: auto;
  width: 614px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: var(--grey-70);
  margin-bottom: 56px;
}

.wrapper {
  padding-bottom: 160px;
}

.wrapper_cards {
  display: flex;
  gap: 32px;
  justify-content: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalContent {
  position: relative;
  background: white;
  padding: 32px;
  border-radius: 24px;
  width: 614px;
  height: 409px;
}

.closeButton {
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
}
.btn_wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--grey-20);
  border-radius: 100px;
  width: 100%;
  height: 55px;
  padding-left: 1px;
  padding-right: 1px;

  margin-top: 26px;
}
.btn {
  width: 273px;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 18px;
  color: var(--grey-80);

  transition: 0.3s;
}
.selected_btn {
  border-radius: 100px;
  padding: 12px 16px;
  width: 273px;
  height: 51px;
  background: var(--grey-90);
  border: none;

  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: var(--grey-0);
}

.time_all {
  display: flex;
  align-items: center;

  margin-top: 40px;
}
.time_title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-100);

  width: 422px;
}

.time_text {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-100);
  margin-right: 16px;
}

.btn_all {
  border: 1px solid var(--grey-50);
  border-radius: 4px;
  width: 24px;
  height: 24px;
}
.selected_all {
  background-image: url("/public/images/checkbox.svg");
  border: none;
}
.options button {
  margin: 10px;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.options button:hover {
  background: #f0f0f0;
}

.container_date {
  display: flex;
  margin-top: 16px;

  align-items: center;
  justify-content: space-between;
}

.datePicker {
  display: flex;
  flex-direction: column;
}

.input_title {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: var(--grey-100);
  margin-left: 20px;
}

.customDateInputWrapper {
  position: relative;
  display: inline-block;
}

.customDateInput {
  border-radius: 54px;
  border: none;
  background: var(--grey-5);
  padding: 12px 16px;
  width: 267px;
  height: 48px;

  font-family: var(--second-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-100);
}

.customDateInput::placeholder {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-40);
}

.customDateInput:focus {
  border: none;
}

.customDateInput::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  opacity: 1;
  background-image: url("/public/images/Calendar.svg");
  cursor: pointer;
}

.calendarIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.export_btn {
  margin-top: 40px;
}

.noVacanciesMessage {
  position: absolute;
  left: 35%;
  top: 50%;
  transform: translate(-27%, -50%);

  font-family: var(--font-family);
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  color: var(--grey-90);
}

.errorMessage {
  position: absolute;
  margin-top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: var(--grey-90);
}

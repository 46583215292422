.wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    width: 100%;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    padding-left: 20px;
    cursor: pointer;
}

.input {
    border-radius: 54px;
    padding: 12px 16px;
    height: 48px;
    border: 1px solid var(--grey-5);
    background: var(--grey-5);
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    width: 100%;
}

.input::placeholder {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: var(--grey-40);
}

.inputWrapper {
    position: relative;
}

.svgWrapper {
    position: absolute;
    right: 16px;
    top: calc(50% - 12px);
    cursor: pointer;
}

.activeEye path {
    stroke: #000000;
}

.input.error {
    border: 1px solid #FF4B4B;
}

.errorMessage {
    margin-top: 16px;
    font-size: 14px;
    color: #FF4B4B;
}
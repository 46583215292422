.footer {
    display: flex;
    justify-content: space-between;
    gap: 48px;
    padding: 24px;
    border-top: 1px solid #D3D3DA;
    background: white;
}

.footer svg {
    box-shadow: 0px 2px 16px 0px #70707029;
    border-radius: 16px;
}

.leftInfo {
    display: flex;
    gap: 32px;
    align-items: flex-end;
}

.copyright {
    color: #5C5C63;
}

.rightInfo {
    display: flex;
    align-items: flex-end;
    gap: 56px;

}

.rightInfo a {
    font-size: 18px;
    font-weight: 500;
    color: #46464B;
    text-decoration: unset;
    border-bottom: 1px solid #46464B;
}
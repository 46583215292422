.signInPage {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.divider {
    margin-bottom: 24px;
}

.info {
    margin-top: 24px;
}

.subtitle {
    font-size: 24px;
    margin-bottom: 16px;
}

.infoTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 4px;
}

.infoList {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.infoListItem {
    font-size: 14px;
    color: #5C5C63;
}

.skipButton {
    position: absolute;
    top: 40px;
    right: 24px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    cursor: pointer;
}

.pageStepWrapper {
    display: flex;
    justify-content: center;
    gap: 64px;
    margin: 20px 0;
}

.pageStep {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid #737379;
    color: #737379;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 22px;
}

.pageStep.active {
    color: #fff;
    border: 1px solid #19191C;
    background: #19191C;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
}

.inputWrapper {
    margin: 20px 0 32px;
    text-align: center;
}

.inputsWrapper {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    margin-bottom: 32px;
}

.dropzone {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='32' ry='32' stroke='%23D3D3DAFF' stroke-width='1' stroke-dasharray='33%2c 26' stroke-dashoffset='60' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 32px;
    padding: 24px;
    width: 100%;
    height: 169px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
}

.dropzone p {
    margin: 0;
}

.dropzone {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='32' ry='32' stroke='%23D3D3DAFF' stroke-width='1' stroke-dasharray='33%2c 26' stroke-dashoffset='60' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 32px;
    padding: 24px;
    width: 100%;
    height: 169px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
}

.inactive {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--grey-100);
    text-align: center;
}

.link {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: var(--primary-color-default);
}

.title {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 22px;
    line-height: 100%;
    color: var(--grey-100);
    text-align: center;

    margin-bottom: 16px;
}

.fileInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-top: 10px;
    background: #f2f4f7;
    padding: 8px 16px;
    border-radius: 16px;
    display: flex;

    width: 313px;
    height: 43px;
}

.openFolder {
    color: #2C64E3;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #2C64E3;
}

.fileError {
    color: red;
    margin-top: 10px;
}

.cvTitle {
    font-size: 22px;
    line-height: 22px;
    color: #19191C;
    margin-bottom: 16px;
}

.infoBlock {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
}

.infoBlockLabel {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 18px;
    line-height: 27px;
    color: #19191C;
}

.infoBlockLabel svg {
    cursor: pointer;
}

.infoInputWrapper > div {
    margin-top: 0;
}

.infoInputWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.infoBlockGeneral {
    display: flex;
    align-items: center;
    gap: 8px;
}

.infoBlockGeneral svg {
    cursor: pointer;
}
.signInPage {
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.openDialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  width: 615px;
  height: 80vh;
  text-align: center;
  overflow: hidden;
}

.dialogContent {
  height: calc(80vh - 140px);
  overflow-y: auto;
  text-align: left;
  background: #ffffff;
  padding: 16px 32px;
}

.dialogTitle {
  padding: 16px 32px;
  position: relative;
  background: #ffffff;
  border-radius: 24px 24px 0 0;
  border-bottom: 1px solid #d3d3da;
}

.dialogTitle h3 {
  text-align: left;
  margin-bottom: 0;
}

.dialogTitle svg {
  position: absolute;
  top: 16px;
  right: 32px;
  cursor: pointer;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.divider {
  margin-bottom: 24px;
}

.info {
  margin-top: 24px;
}

.subtitle {
  font-size: 24px;
  margin-bottom: 16px;
}

.infoTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 4px;
}

.infoList {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.infoListItem {
  font-size: 14px;
  color: #5c5c63;
}

.skipButton {
  position: absolute;
  top: 40px;
  right: 24px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  cursor: pointer;
}

.pageStepWrapper {
  display: flex;
  justify-content: center;
  gap: 64px;
  margin: 20px 0;
}

.pageStep {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #737379;
  color: #737379;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 22px;
}

.pageStep.active {
  color: #fff;
  border: 1px solid #19191c;
  background: #19191c;
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;
}

.inputWrapper {
  margin: 20px 0 32px;
  text-align: center;
}

.inputsWrapper {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  margin-bottom: 32px;
}

.dropzone p {
  margin: 0;
}

.dropzone {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='32' ry='32' stroke='%23D3D3DAFF' stroke-width='1' stroke-dasharray='33%2c 26' stroke-dashoffset='60' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 32px;
  padding: 24px;
  width: 100%;
  height: 169px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.inactive {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--grey-100);
  text-align: center;
}

.link {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: var(--primary-color-default);
}

.title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
  color: var(--grey-100);
  text-align: center;

  margin-bottom: 16px;
}

.fileInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  margin-top: 10px;
  background: #f2f4f7;
  padding: 8px 16px;
  border-radius: 16px;
  display: flex;
  width: 313px;
  height: 43px;
}

.openFolder {
  color: #2c64e3;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid #2c64e3;
}

.fileError {
  color: red;
  margin-top: 10px;
}

.cvTitle {
  font-size: 22px;
  line-height: 22px;
  color: #19191c;
  margin-bottom: 16px;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.infoBlockLabel {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 18px;
  line-height: 27px;
  color: #19191c;
}

.infoBlockLabel svg {
  cursor: pointer;
}

.infoInputWrapper > div {
  margin-top: 0;
}

.infoInputWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.infoBlockGeneral {
  display: flex;
  align-items: center;
  gap: 8px;
}

.infoBlockGeneral svg {
  cursor: pointer;
}

.dialogFooter {
  padding: 16px 32px;
  display: flex;
  border-top: 1px solid #d3d3da;
  gap: 32px;
  align-items: center;
  border-radius: 0 0 24px 24px;
  background: #ffffff;
}

.saved {
  position: absolute;
  left: 50%;
  transform: translateX(-70%);

  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: var(--grey-100);
}

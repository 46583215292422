

.title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
  text-align: center;
  color: var(--grey-100);
}

.wrapper {
  margin-top: 70px;
  margin-bottom: 128px;
}

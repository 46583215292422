.subaction {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 24px;
    padding-left: 20px;
    cursor: pointer;
}

.signInPage {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
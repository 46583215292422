.wrapper {
  position: relative;
  width: 100%;
  height: 790px;
  border: 1px solid var(--grey-20);
  border-radius: 24px;
  padding-bottom: 24px;
}
.wrapper_small {
  position: relative;
  width: 100%;
  height: 564px;
  border: 1px solid var(--grey-20);
  border-radius: 24px;
  padding-bottom: 24px;
}

.link {
  text-decoration-line: none;
  width: 100%;
}
.header {
  border-bottom: 1px solid var(--grey-20);
}
.title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}

.header_title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: var(--grey-100);
}

.no_application {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%);

  font-family: var(--font-family);
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  color: var(--grey-90);
  text-wrap: nowrap;
}

.wrapper_content {
  align-content: center;
  border-radius: 16px;
  padding: 8px 24px;
  max-width: 880px;
  height: 68px;
  margin: auto;
  margin-top: 16px;
  box-shadow: 0 2px 16px 0 rgba(112, 112, 112, 0.16);
  background: var(--grey-0);
}

.titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: var(--grey-80);
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.name {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-100);
}

@media (max-width: 1700px) {
  .wrapper_content {
    width: 710px;
  }
  .header_title {
    font-size: 20px;
  }
}

@media (max-width: 1440px) {
  .no_application {
    font-size: 25px;
  }
  .wrapper_content {
    width: 655px;
  }
}

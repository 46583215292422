.swiper {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  
  transition: transform 0.3s;
  background: #fff;
  border-radius: 32px;
  width: 456px !important;
  height: 200px;
}

.swiper-slide-active {
  
  width: 614px !important;
  height: 263px !important;
}



.wrapper_top {
  border-radius: 24px 24px 0 0 ;
  border: 1px solid var(--grey-20);
  border-bottom: none;
  padding: 24px;
  display: flex;
  align-items: center;
}
.wrapper_top-active {
  border-radius: 32px 32px 0 0 ;
  padding: 32px;
  display: flex;
  align-items: center;
}

.arrow_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border-radius: 50%;
  width: 56px;
  height: 54px;
  background-color: var(--grey-0);
}

.title {
  margin: 0;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: var(--grey-100);
}

.title-active {
  font-size: 28px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: var(--grey-0);
  box-shadow: 0 2px 16px 0 rgba(112, 112, 112, 0.16);
}

.icon-active {
  width: 56px;
  height: 54px;
}

.wrapper_bottom {
  border: 1px solid var(--grey-20);
  border-top: none;
  width: 100%;
  height: 111px;
  border-radius: 0 0 24px 24px;
  background: var(--grey-5);
  padding: 24px 24px 0;
  box-sizing: border-box;
  font-size: 14px;
}

.wrapper_bottom-active {
  height: 145px;
  font-size: 18px;
  padding: 32px 32px 0;
  border-radius: 0 0 32px 32px;
}

.text {
  font-family: var(--font-family);
  font-weight: 500;

  line-height: 150%;
  color: var(--grey-100);
}

/* Pagination */
.swiper-pagination {
  position: absolute;
  margin-bottom: 16px;
  width: 300px;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: var(--grey-20);
  opacity: 0.5;
  transition: width 0.3s, opacity 0.3s;
}

.swiper-pagination-bullet-active {
  width: 218px;
  background: var(--primary-color-default);
  border-radius: 37px;
  opacity: 1;
}

/* Navigation */
.swiper-button-next,
.swiper-button-prev {
  margin-bottom: 10px;
  color: #000;
  position: absolute;
  top: auto;
  bottom: 20px;
  width: 56px;
  height: 56px;
  background: var(--grey-10);
  border-radius: 50%;
  background-size: 6px 12px;
  background-repeat: no-repeat;
  transform: translateY(50%);
  font-size: 12px;
}

.swiper-button-next:after {
  content: 'next';
  font-size: 14px !important;
  font-weight: 700;
}
.swiper-button-prev:after {
  content: 'prev';
  font-size: 14px !important;
  font-weight: 700;
}

.swiper-button-prev {
  left: 472px;
}

.swiper-button-next {
  right: 472px;
}

.subText {
    font-weight: 400;
    font-size: 18px;
    margin-top: 24px;
    line-height: 27px;
    text-align: center;
}

.subtitle {
    font-size: 24px;
    margin-bottom: 16px;
}

.paragraph {
    font-size: 18px;
    margin-bottom: 40px;
}

.paragraph span {
    font-weight: bold;
}

.sendAgain {
    cursor: pointer;
    font-weight: bold;
    color: #2C64E3;
}

.codeSent {
    color: #737379;
    font-weight: bold;
}

.signInPage {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signInPage {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.divider {
    margin-bottom: 24px;
}

.info {
    margin-top: 24px;
}

.infoTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 4px;
}

.infoList {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.infoListItem {
    font-size: 14px;
    color: #5C5C63;
}

.authWrapper {
    max-width: 600px;
    width: 100%;
    padding: 40px;
    margin: auto;
    border-radius: 32px;
    box-shadow: 0 2px 16px 0 rgba(112, 112, 112, 0.16);
    font-family: "Satoshi", sans-serif;
}

.topAction {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 40px;
}

.backButton  {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EAEAF0;
    border-radius: 50%;
}

.title {
    text-align: center;
    width: 100%;
}

.subtitle {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.subtitle span {
    color: #2c64e3;
}

.mb {
    margin-bottom: 2rem;
}
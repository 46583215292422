.wrapper {
    margin-bottom: 24px;
}

.codeWrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.codeInput {
    width: 56px;
    height: 56px;
    font-size: 24px;
    text-align: center;
    border: 1px solid #000;
    border-radius: 5px;
    outline: none;
}

.codeInput:focus {
    border: 1px solid #2C64E3;
}

.error .codeInput {
    border: 1px solid #FF4B4B!important;
}

.errorMessage {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 24px;
    color: #FF4B4B;
    font-size: 16px;
    font-weight: 500;
}
.title {
  margin-top: 80px;
  margin-bottom: 32px;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 64px;
  line-height: 100%;
  text-align: center;
  color: var(--grey-100);
}

.subtitle {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: var(--grey-80);
  width: 921px;
  margin: auto;
}

.button {
  margin: auto;
  margin-top: 16px;
  margin-bottom: 72px;
  width: 614px;
}

.image{
  width: 100%;
}

.signInPage {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 24px;
}

.list_item {
    position: relative;
    padding-left: 28px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: var(--grey-80);
}
.list_item:not(:last-child) {
    margin-bottom: 8px;
}

.list_item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 13px;
    background: url("/public/images/list_point.svg") no-repeat center center;
    background-size: contain;
}

.subtitle {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 16px;
}

.subtitle span {
    color: #2C64E3;
    margin-bottom: 16px;
}

.infoText {
    color: #46464B;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 40px;
}

.benefitsTitle {
    color: #19191C;
    margin-bottom: 16px;
}

.cyclesList {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.cycleItem.active {
    border: 1px solid #2C64E3;
}

.cycleItem {
    width: 50%;
    padding: 16px;
    border-radius: 24px;
    background: white;
    border: 1px solid #D3D3DA;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
}

.cycleItem h4 {
    color: #2C64E3;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
}

.cyclePrice {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #5C5C63;
}

.discount {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 4px 8px;
    border-radius: 38px;
    background: #FF4B4B;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 21px;
}

.benefitTitle {
    margin-bottom: 16px;
}

.billingCycleWrapper {
    margin-bottom: 24px;
}

.checkout {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkoutItem {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.checkoutItem h3 {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
}

.price {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #46464B;
}

.futurePayment {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #46464B;
}

.rightTitle {
    text-align: right;
}

.subText,
.subText a {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #46464B;
    margin-top: 16px;
}

.subText a {
    text-decoration: none;
    border-bottom: 1px solid;
}
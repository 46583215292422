.skillsInputContainer {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 24px;
}

.label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    padding-left: 20px;
}

.skillsWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #F7F8FB;
    padding: 16px;
    border-radius: 24px;
    gap: 8px;
}

.skillItem {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: #EAEAF0;
    border-radius: 35px;
    font-size: 18px;
    font-weight: 500;
    color: #303033;
    position: relative;
}

.skillInput {
    border: none;
    outline: none;
    font-size: 18px;
    min-width: 125px;
    color: #303033;
    padding: 8px 16px;
    background: #EAEAF0;
    border-radius: 35px;
    cursor: pointer;
}

.skillInput::placeholder {
    color: #303033;
}

.removeIcon {
    display: none;
    position: absolute;
    top: -5px;
    right: 2px;
    cursor: pointer;
}

.skillItem:hover .removeIcon {
    display: block;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.logout-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 32px;
    border-radius: 24px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    width: 615px;
    min-height: 245px;
    text-align: center;
}

h2 {
    text-align: left;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 40px;
}

.close-button {
    position: absolute;
    top: 32px;
    right: 32px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

p {
    text-align: left;
}

.buttons {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 40px;
}

.cancel-button, .logout-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

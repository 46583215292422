.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  flex: 0 1 auto;
}

.header svg {
  box-shadow: 0px 2px 16px 0px #70707029;
  border-radius: 16px;
}

.centralNav {
  display: flex;
  gap: 48px;
  font-size: 18px;
  flex-grow: 1;
  justify-content: center;
}

.centralNav div {
  cursor: pointer;
}

.rightActionButtons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.rightActionButtons button {
  white-space: nowrap;
  width: 140px;
}

.goDashboard {
  width: 299px;
}

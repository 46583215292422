.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modalContent {
  background-color: #fff;
  border-radius: 24px;
  padding: 32px;
  width: 614px;
  height: 598px;
  position: relative;
}

.header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
  color: var(--grey-100);
}

.text {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-100);

  margin-bottom: 40px;
}
.labelText {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: var(--grey-100);
  margin-left: 20px;
}
.labelTextArea {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-100);
  margin-top: 24px;
  margin-bottom: 8px;
  margin-left: 20px;
}
.textArea {
  border-radius: 16px;
  padding: 16px;
  width: 550px;
  height: 173px;
}
.textArea::placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: var(--grey-50);
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
}

.closeButton img {
  width: 24px;
  height: 24px;
}

.button{
  margin-top: 40px;
}
.button_main {
  display: flex;
  text-align: center;
  width: 100%;
  border-radius: 100px;
  padding: 10px 16px;
  background: var(--primary-color-default);

  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-0);
  transition: all 0.3s;
}

.button_main:hover {
  box-shadow: 0 0 12px 0 var(--primary-color-default);
}
.button_main:focus {
  background: var(--primary-color-dark);
}

.button_second {
  display: flex;
  text-align: center;
  width: 100%;
  border-radius: 100px;
  padding: 10px 16px;
  border: 1px solid var(--grey-70);

  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-80);

  transition: all 0.3s;
}
.button_second:hover {
  border: 1px solid var(--grey-60);
  color: var(--grey-70);
}
.button_second:focus {
  border: 1px solid var(--grey-90);
  color: var(--grey-90);
}

.button_disabled {
  background: #D7E7FF;
  cursor: default;
}

.button_disabled:hover {
  background: #D7E7FF!important;
  cursor: default!important;
  box-shadow: unset!important;
}

.button_disabled:focus {
  background: #D7E7FF!important;
  cursor: default!important;
  box-shadow: unset!important;
}

.text {
  margin: auto;
}

.authWrapper {
    max-width: 600px;
    width: 100%;
    padding: 40px;
    margin: auto;
    border-radius: 32px;
    box-shadow: 0 2px 16px 0 rgba(112, 112, 112, 0.16);
    font-family: "Satoshi", sans-serif;
}

.topAction {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 40px;
}

.backButton  {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EAEAF0;
    border-radius: 50%;
}
.wrapper {
  display: flex;
  align-items: center;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--grey-10);
  padding: 16px;
  width: 40px;
  height: 40px;
}

.btn:disabled{
  cursor: default;
}
.num_page {
  margin-right: 24px;
  margin-left: 24px;

  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: var(--grey-100);
}

.disabled_left {
  opacity: 0.3;
}
.disabled_right {
  opacity: 0.3;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalContent {
  position: relative;
  background: white;
  padding: 32px;
  border-radius: 24px;
  width: 614px;
}

.closeButton {
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
}
.btn_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  padding-left: 1px;
  padding-right: 1px;
  margin-top: 26px;
}


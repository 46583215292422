.signInPage {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.divider {
    margin-bottom: 24px;
}

.info {
    margin-top: 24px;
}

.subtitle {
    font-size: 24px;
    margin-bottom: 16px;
}

.infoTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 4px;
}

.infoList {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.infoListItem {
    font-size: 14px;
    color: #5C5C63;
}

.subAction {
    width: 100%;
    margin-top: 16px;
    font-size: 18px;
    line-height: 27px;
    color: #46464B;
    cursor: pointer;
    text-align: center;
}
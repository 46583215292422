.selectWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.label {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding-left: 20px;
}

.reactSelectControl {
    padding: 10px 16px;
    border-radius: 54px;
    background: #f7f8fb;
    color: #a6a6ad;
    height: 48px;
    border: unset;
    box-shadow: none;
}

.react-select__control--is-focused {
    border: unset;
    box-shadow: none;
}

.react-select__input {
    color: #000;
}

.react-select__single-value {
    color: #000;
}

.react-select__placeholder {
    color: #a6a6ad;
}

.arrow {
    transition: 0.3s;
}

.arrowFocused {
    transform: rotateX(180deg);
}
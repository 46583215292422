.wrapper {
  display: block;
  width: 492px;
  height: 322px;
 
}

.wrapper_top {
  border-radius: 32px 32px 0 0;
  padding: 32px;
  border: 1px solid #d3d3da;
  border-bottom: none;
}


.arrow_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  border-radius: 50%;
  width: 56px;
  height: 54px;
  background-color: var(--grey-0);
  box-shadow: 0 2px 16px 0 rgba(112, 112, 112, 0.16);
}

.title {
  margin: 0;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: var(--grey-100);
}
.wrapper_bottom {
  height: 226px;
  border-radius: 0 0 32px 32px;
  background: var(--grey-5);
  padding: 32px 32px 0;
  box-sizing: border-box;
  border: 1px solid #d3d3da;
  border-top: none;
}
.text {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-100);
}

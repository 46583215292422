.signInPage {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.divider {
    margin-bottom: 24px;
}

.info {
    margin-top: 24px;
}

.subtitle {
    font-size: 24px;
    margin-bottom: 16px;
}

.infoTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 4px;
}

.infoList {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.infoListItem {
    font-size: 14px;
    color: #5C5C63;
}

.primaryActions {
    display: flex;
    align-items: center;
    gap: 16px;
}

.cancelBtn {
    font-size: 18px;
    color: #46464B;
    white-space: nowrap;
}

.subActionsList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 24px 0 16px;
}

.subAction {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    color: #19191C;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
}

.secondaryActions {
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.logoutButton {
    font-size: 18px;
    line-height: 27px;
    color: #FF4B4B;
    margin: 0 auto;
}
.subaction {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 24px;
    padding-left: 20px;
}

.termsText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #A6A6AD;
    margin-top: 40px;
}

.link {
    cursor: pointer;
    border-bottom: 1px solid #A6A6AD;
}

.divider {
    margin-bottom: 24px;
}

.signInPage {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signInPage {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topAction {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 40px;
}

.backButton  {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EAEAF0;
    border-radius: 50%;
}

.subscriptionWrapper {
    max-width: 930px;
    width: 100%;
    border-radius: 32px;
}

.topSection {
    background: #303033;
    color: white;
    padding: 32px;
    min-height: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 32px 32px 0 0;
}

.content {
    max-width: 930px;
    width: 100%;
    margin: 0 auto;
}

.bottomSection {
    background: #F7F8FB;
    border-radius: 0 0 32px 32px;
    padding: 32px;
}

.infoSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    margin-bottom: 24px;
}

.infoItem {
    display: flex;
    gap: 40px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: #46464B;
}

.left {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.infoSecond {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: #19191C;
}

.buttons {
    width: 100%;
    display: flex;
    align-content: center;
    gap: 24px;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
}
.left_wrapper {
  width: 928px;
}
.right_wrapper {
  width: 928px;
}

@media (max-width: 1700px) {
  .left_wrapper {
    max-width: 750px;
  }
  .right_wrapper {
    max-width: 750px;
    margin-left: 20px;
  }
}

@media (max-width: 1440px) {
  .left_wrapper {
    width: 700px;
    
  }
  .right_wrapper {
    width: 700px;
  }
}



.wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  border: 1px solid var(--grey-20);
  border-radius: 24px;
  padding: 40px;
  width: 100%;
  height: 289px;
  z-index: 0;
}
.title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: var(--grey-100);
}

.subtitle {
  margin-top: 16px;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-80);
}

.dunut_wrapper {
  position: relative;
  width: 209px;
  height: 209px;
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 209px;
  height: 209px;
  border: 32px solid #eaeaf0;
  border-radius: 50%;
  background-color: #fff;
}

.count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  font-family: var(--font-family);
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
  color: var(--grey-100);
}

.dunut_count {
  position: absolute;
  top: -10px;
  left: -6px;
  width: 220px;
  height: 220px;
}

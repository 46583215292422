@font-face {
  font-family: "Satoshi-Regular";
  src: url("../public/fonts/Satoshi-Regular.woff2") format("woff2"),
    url("../public/fonts/Satoshi-Regular.woff") format("woff"),
    url("../public/fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi-Medium";
  src: url("../public/fonts/Satoshi-Medium.woff2") format("woff2"),
    url("../public/fonts/Satoshi-Medium.woff") format("woff"),
    url("../public/fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi-Bold";
  src: url("../public/fonts/Satoshi-Bold.woff2") format("woff2"),
    url("../public/fonts/Satoshi-Bold.woff") format("woff"),
    url("../public/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

:root {
  --grey-100: #19191c;
  --grey-90: #303033;
  --grey-80: #46464b;
  --grey-70: #5c5c63;
  --grey-60: #737379;
  --grey-50: #8c8c94;
  --grey-40: #a6a6ad;
  --grey-30: #b5b5be;
  --grey-20: #d3d3da;
  --grey-10: #eaeaf0;
  --grey-5: #f7f8fb;
  --grey-0: #fff;
  --primary-color-default: #2c64e3;
  --primary-color-light: #d7e7ff;
  --primary-color-dark: #1048c6;
  --system-colors-green: #5bc483;
  --system-colors-yellow: #ffc71a;
  --system-colors-red: #ff4b4b;
  --system-colors-blue: #5c75fa;
}
:root {
  --font-family: "Satoshi", sans-serif;
  --second-family: "Inter", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  font-family: var(--font-family, "Satoshi", sans-serif);
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border: none;
  background: transparent;
  color: inherit;
}
.container {
  max-width: 1558px;
  margin: auto;
}

.special-container {
  max-width: 1872px;
  margin: auto;
}

@media (max-width: 1700px) {
  .special-container {
    max-width: 1500px;
  }
}
@media (max-width: 1440px) {
  .special-container {
    max-width: 1410px;
  }
}

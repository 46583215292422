.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 32px;
}

.logo {
  box-shadow: 0px 2px 16px 0px #70707029;
  border-radius: 16px;
  cursor: pointer;
}
.link{
  text-decoration: none;
}

.user {
  display: flex;
  align-items: center;
}

.name_user {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-100);

  margin-left: 16px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: var(--grey-100);
}
.btn_wrapper {
  display: flex;
}
.edit {
  display: flex;
  width: 300px;
  padding: 12px 58.5px;
}
.btn_text {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: var(--grey-100);
  margin-left: 8px;
}
.special_btn {
  padding-left: 74px;
  padding-right: 74px;
}
.btn {
  width: 377px;
  margin-left: 16px;
}

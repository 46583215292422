body {
    background: white;
}

.pageWrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 40px;
}

.pageTitle {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 32px;
}

.publication {
    margin-bottom: 50px;
    font-size: 18px;
    color: #5C5C63;
    line-height: 27px;
}

.paragraphWrapper {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    max-width: 770px;
}

.mtSubtitle {
    margin-top: 24px;
}

.title {
    color: #19191C;
    font-size: 24px;
    margin-bottom: 32px;
}

.listTitle:before {
    content: "1.";
    position: absolute;
    left: 24px;
}

.listTitle,
.listTitle2{
    position: relative;
    padding-left: 48px!important;
}

.listTitle2:before {
    content: "2.";
    position: absolute;
    left: 24px;
}

.subtitle {
    font-size: 20px;
    margin-bottom: 24px;
    color: #303033;
    padding-left: 24px;
}

.paragraph {
    font-size: 18px;
    color: #46464B;
    line-height: 27px;
    margin: 0;
    padding-left: 24px;
}

.pageList {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #46464B;
    line-height: 27px;
}

.pageList li {
    font-size: 18px;
}

a {
    color: #46464B;
}
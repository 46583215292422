.start-end-wrapper {
  padding: 4px;
}

.calendar-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey-20);
  border-radius: 24px;
  padding: 40px;
  max-width: 100%;
  height: 262px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  align-items: center;
}

.calendar-title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: var(--grey-100);
}

.calendar-month {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-100);
}

.calendar-text {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-80);
}

.calendar-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 48px;
}

.nav-button {
  background: var(--grey-10);
  padding: 16px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.nav-button:disabled {
  color: lightgray;
  cursor: not-allowed;
}

.days-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 629px;
  padding-left: 10px;
  padding-right: 10px;
}

.day {
  margin: 0 5px;
  padding: 16px;
  width: 88px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-80);
  border-radius: 50%;
}

@media(max-width: 1700px) {
  .day{
    width:70px;
  }
}
@media(max-width: 1440px) {
  .day{
    width:61px;
  }
}

.selected-start {
  margin-left: 0px;
  max-width: 64px;
  background: var(--primary-color-default);
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: var(--grey-0);
  border-radius: 50%;
  justify-content: center;
}
.selected-end {
  margin-right: 0px;
  max-width: 64px;
  background: var(--primary-color-default);
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: var(--grey-0);
  border-radius: 50%;
  justify-content: center;
}

.none-wrapper {
  background-color: #fff;
}
.in-range {
  background-color: var(--grey-5);
  border-radius: 0%;
  max-width: 908px;
}
.start-wrapper {
  background-color: var(--grey-5);
  border-radius: 50% 0 0 50%;
}

.end-wrapper {
  background-color: var(--grey-5);
  border-radius: 0 50% 50% 0;
}

.disabled_next {
  opacity: 0.4;
}

.nav-button:disabled {
  cursor: default;
}

.tabsWrapper {
    width: 100%;
    display: flex;
    border-radius: 25px;
    border: 1px solid #D3D3DA;
    padding: 1px;
    gap: 6px;
    margin-bottom: 40px;
}

.tabItem {
    color: #46464B;
    transition: 0.3s;
    padding: 12px 24px;
    width: 50%;
    border-radius: 25px;
    font-weight: normal;
    text-align: center;
    cursor: pointer;
}

.tabItem.active {
    color: white;
    background: #303033;
    font-weight: bold;
}

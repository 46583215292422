.wrapper {
  display: block;
  width: 448px;
}

.wrapper_top {
  border: 1px solid var(--grey-20);
  border-bottom: none;
  border-radius: 32px 32px 0 0;
  padding: 24px;
  width: 100%;
  height: 171px;
  position: relative;
}

.save {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 32px;
  border-radius: 0 0 8px 8px;
  padding: 4px 8px;
  width: 80px;
  height: 29px;
  background: var(--primary-color-light);
}
.save_text {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: var(--primary-color-default);
}

.period {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  margin-top: 58px;
  margin-bottom: 8px;
}

.subtitle {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--grey-70);
}

.wrapper_bottom {
  border: 1px solid var(--grey-20);
  background: var(--grey-5);
  border-radius: 0 0 32px 32px;
  padding: 24px;
  width: 448px;
  height: 290px;
}

.wrapper_price {
  display: flex;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
  color: var(--grey-100);
  margin-left: 10px;
}

.old_price {
  position: relative;
  margin-right: 35px;
}

.old_price::before {
  border-bottom: 3px solid var(--system-colors-red);
  position: absolute;
  content: "";
  width: 99px;
  left: -13px;
  top: 11px;
  transform: rotate(-10deg);
  border-radius: 10px;
}

.new_price {
  margin-right: 16px;
}

.price_text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--grey-80);
}

.btn {
  margin-top: 16px;
}

.list {
  list-style: none;
  padding-left: 0;
  margin-top: 24px;
}

.list_item {
  position: relative;
  padding-left: 28px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey-80);
}
.list_item:not(:last-child) {
  margin-bottom: 8px;
}

.list_item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
  height: 13px;
  background: url("/public/images/list_point.svg") no-repeat center center;
  background-size: contain;
}
